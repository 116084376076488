import { Github } from "@styled-icons/feather/Github";
import { Linkedin } from "@styled-icons/feather/Linkedin";
import { Mail } from "@styled-icons/feather/Mail";

const Icons = {
	Github,
	Linkedin,
	Mail
};

export default Icons;
